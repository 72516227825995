import BaseStyles from "../components/common.module.scss";
import Navbar from "../components/Navbar/Navbar";
import PageContainer from "../components/PageContainer/PageContainer";
import WindowManagement from "../components/WindowManagement/WindowManagement";

export default function NewsPage() {
  return (
    <>
      <div className={BaseStyles.splashScreenOpacity} />
      <Navbar activePage={"Управление"} />
      <PageContainer>
        <WindowManagement />
      </PageContainer>
    </>
  );
}
