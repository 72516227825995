import React, { useEffect, useRef, useState } from "react";
import styles from "./SelectModule.module.scss";
import classNames from "classnames";

export interface SelectModuleProps {
  /** Список опций */
  options: { [key: string]: string };
  /** Функция для обновления выбранного значения */
  onSelect: (selectedOption: string) => void;
  /** Флаг, указывающий, открыт ли модуль */
  isOpen: boolean;
  /** Значение выделяет выбранную опцию */
  selectedOption?: string;
  /** Плавное открытие, но быстрое закрытие */
  isQuickClosure?: boolean;
  /** Дополнительные стили для контейнера */
  style?: React.CSSProperties;
}

export default function SelectModule({
  options,
  onSelect,
  isOpen,
  selectedOption,
  isQuickClosure,
  style,
}: SelectModuleProps) {
  const [height, setHeight] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = dropdownRef.current;
    const height = element ? element.offsetHeight : 0;
    setHeight(height);
  }, [options]);

  return (
    <div
      className={styles.transitionContainer}
      style={{
        maxHeight: isOpen ? height : 0,
        transitionDuration: isQuickClosure
          ? isOpen
            ? `${height * 1.5}ms`
            : "0ms"
          : `${height * 1.5}ms`,
        ...style,
      }}
    >
      <div ref={dropdownRef} className={styles.dropdownList}>
        {Object.entries(options).map(([key, value]) => (
          <div
            key={key}
            className={styles.dropdownItem}
            onClick={() => onSelect(key)}
            role="button"
            aria-expanded={isOpen}
          >
            <div
              className={classNames(styles.itemTitle, {
                [styles.checked]: selectedOption === key,
              })}
            >
              {value}
            </div>
            {selectedOption && (
              <div
                className={classNames(styles.checkmark, {
                  [styles.checked]: selectedOption === key,
                })}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
