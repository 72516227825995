const A_min = 10 ** 2; // 100

export function calculateSquareSide(value: number, valuesRange: number[]) {
    const v_min = Math.min(...valuesRange);
    const v_max = Math.max(...valuesRange);
    // Вычисляем площадь пропорционально значению, без ограничения максимального размера
    const area = A_min + ((value - v_min) * (A_min * 100)) / (v_max - v_min);
    // Возвращаем сторону квадрата
    return Math.sqrt(area);
  }
