import React from "react";

type PieChartData = {
  color: string;
  value: number;
};

type PieChartProps = {
  data: PieChartData[];
  radius: number;
};

const PieChart: React.FC<PieChartProps> = ({ data, radius }) => {
  const size = radius * 2;
  const center = radius;

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) => {
    const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const createPath = (startAngle: number, endAngle: number): string => {
    const start = polarToCartesian(center, center, radius, endAngle);
    const end = polarToCartesian(center, center, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
      `M ${start.x} ${start.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
      `L ${center} ${center}`,
      "Z",
    ].join(" ");
  };

  const filteredData = data.filter((item) => item.value !== null);
  const total = filteredData.reduce((sum, item) => sum + (item.value || 0), 0);

  if (filteredData.length === 1) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill={filteredData[0].color}
          stroke="white"
          strokeWidth="1"
        />
      </svg>
    );
  }

  let startAngle = 0;
  const paths = filteredData.map((item, index) => {
    const percentage = (item.value! / total) * 100; // `value!` так как мы уже отфильтровали `null`
    const angle = (percentage / 100) * 360;
    const endAngle = startAngle + angle;

    const path = createPath(startAngle, endAngle);
    startAngle = endAngle;

    return (
      <path
        key={index}
        d={path}
        fill={item.color}
        stroke="white"
        strokeWidth="1"
      />
    );
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {paths}
    </svg>
  );
};

export default PieChart;