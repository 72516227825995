import { Form, FormikProvider, useFormik } from "formik";
import {
  ModalElement,
  ModalWindowProps,
} from "../../components/ModalWindow/ModalWindow";
import styles from "./Form.module.css";
import { TextArea } from "../Components";

const initialValues: ModalWindowProps = {
  isVisible: true,
  title: "Заголовок",
  subtitle: "Подзаголовок",
  content: `<p>Расположенному в г. Серафимович монастырю 19 августа 2002 года, по официальным документам, исполнилось 350 лет, а в действительности, его история перешагнула и 360-летний рубеж.</p>
<p>В 1638 году напротив устья реки Медведицы действовала так называемая «Межгорская пустынь», принимавшая на покой казаков:</p>

<span>предоставление земельных участков под застройку, а также для коллективного садоводства и огородничества;</span>
<span>деятельность, влекущая за собой нарушение почвенного покрова и геологических обнажений;</span>
<span>заготовка растительной земли;</span>

<img src="
https://storage.yandexcloud.net/atlas-assets/diagrams/2-2%20%D0%9A%D0%B0%D0%BB%D0%BC%D0%93%D0%A3/%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA_1_%D0%B4%D0%BE%D0%BB%D1%8F_%D0%92%D0%94%D0%A1.png
"/>
`,
  onClose: () => console.log("Close ModalWindow"),
};

export default function AddModalForm() {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const { values, handleChange } = formik;

  return (
    <div className={styles.modalFormContainer}>
      <ModalElement
        isVisible={false}
        title={values.title}
        subtitle={values.subtitle}
        content={values.content}
        onClose={() => {}}
      />
      <FormikProvider value={formik}>
        <Form>
          <div className={styles.container}>
            <h4 className={styles.heading}>Модальное окно</h4>
            <TextArea
              name="title"
              label="Заголовок"
              onChange={handleChange}
              value={values.title}
            />
            <TextArea
              name="subtitle"
              label="Подзаголовок"
              onChange={handleChange}
              value={values.subtitle}
            />
            <TextArea
              name="content"
              label="Контент с версткой"
              onChange={handleChange}
              value={values.content}
              rows={34}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}
