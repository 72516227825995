import React, { useEffect, useState } from "react";
import styles from "./WindowManagement.module.scss";
import StatusLabel from "../StatusLabel/StatusLabel";
import { getPostsList } from "../../common/ApiService";
import { MenuItem } from "../../types/content-types";
import ButtonThreeDot from "../ButtonThreeDot/ButtonThreeDot";
import { formatDateTime } from "../../common/utils";

export type WindowManagementProps = {};

const WindowManagement: React.FC<WindowManagementProps> = () => {
  const [posts, setPosts] = useState<MenuItem[]>([]);
  const [sortConfig, setSortConfig] = useState({
    key: "type",
    direction: "ascending",
  });

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsList = await getPostsList();
        setPosts(postsList);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const options = {
    remove: "Снять с публикации",
    edit: "Редактировать",
    preview: "Просмотр",
  };

  const handleSelectAction = (post: MenuItem, option: string) => {
    if (option === "preview") {
      post.type === 1
        ? window.open(`/map/${post.id}`, "_blank")
        : window.open(post.url, "_blank");
    }
    if (option === "edit") {
      const postType = post.type === 1 ? "map" : "file";
      window.open(`/edit/${postType}/${post.id}`, "_blank");
    }
  };

  // console.log("posts", posts);

  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>Управление контентом</div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div className={styles.thDiv}>
                <p>Тип</p>
                <div className={styles.arrow} />
              </div>
            </th>
            <th>
              <div className={styles.thDiv}>
                <p>Название</p>
                <div className={styles.arrow} />
              </div>
            </th>
            <th>
              <div className={styles.thDiv}>
                <p>Дата изменений</p>
                <div className={styles.arrow} />
              </div>
            </th>
            <th>
              <div className={styles.thDiv}>
                <p>Статус</p>
                <div className={styles.arrow} />
              </div>
            </th>
            <th>
              <div
                className={styles.thLastDiv}
                style={{ border: "none", paddingRight: 16 }}
              >
                Действия
              </div>
            </th>
          </tr>
        </thead>
        {!!posts.length && (
          <tbody>
            {posts.map((post, index) => (
              <tr key={index}>
                <td>
                  <StatusLabel
                    color={post.type === 1 ? "green" : "blue"}
                    title={post.type === 1 ? "Карта" : "Документ"}
                  />
                </td>
                <td>{post.title}</td>
                <td>{formatDateTime(post.updated)}</td>
                <td>
                  <StatusLabel
                    color={post.published ? "green" : "gray"}
                    title={post.published ? "Опубликовано" : "Не опубликовано"}
                  />
                </td>
                <td>
                  <div className={styles.buttonContainer}>
                    <ButtonThreeDot
                      options={options}
                      onSelect={(option) => handleSelectAction(post, option)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default WindowManagement;
