import { createSlice } from "@reduxjs/toolkit";

type State = {
  isVisible: boolean;
  title?: string;
  subtitle?: string;
  content?: string;
};

const initialState: State = {
  isVisible: false,
};

const modalWindowDataSlice = createSlice({
  name: "modalWindow",
  initialState,
  reducers: {
    setModalState: (state, action: { payload: State }) => {
      state.isVisible = action.payload.isVisible;
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
      state.content = action.payload.content;
    },
    open: (state) => {
      state.isVisible = true;
    },
    close: (state) => {
      state.isVisible = false;
    },
  },
});

export const modalWindowReducer = modalWindowDataSlice.reducer;

export const { open, close, setModalState } = modalWindowDataSlice.actions;
