import { useRef, useState } from "react";
import styles from "./MenuButton.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import classNames from "classnames";
import SelectModule from "../SelectModule/SelectModule";

export interface MenuButtonProps {
  /** Список опций */
  options: { [key: string]: string };
  /** Функция для обновления выбранного значения */
  onSelect: (selectedOption: string) => void;
  /** Значение выделяет выбранную опцию */
  selectedOption?: string;
  /** Дополнительные стили для контейнера */
  style?: React.CSSProperties;
  /** Вид кнопки */
  type?: "burgerMenu" | "userMenu";
}

export default function MenuButton({
  options,
  onSelect,
  selectedOption,
  style,
  type = "burgerMenu",
}: MenuButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(mainRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className={styles.menuButtonContainer} ref={mainRef} style={style}>
      <div
        className={classNames(styles.menuButton, styles[type], {
          [styles.menuButtonOpen]: isOpen,
          [styles[`${type}Open`]]: isOpen,
        })}
        onClick={toggleDropdown}
      />
      <SelectModule
        options={options}
        onSelect={handleSelect}
        selectedOption={selectedOption}
        isOpen={isOpen}
        style={{ transform: "translateY(8px)" }}
      />
    </div>
  );
}
