import { RLayerVector, RFeature, ROverlay } from 'rlayers';
import { useSelector } from "react-redux";
import { CustomInfoWindow } from "./maps/common/CustomInfowindowCreator";

export function InfoWindowOnClick() {

    const selectedFeature = useSelector((state: any) => state.selectFeature.feature);

    const selectedFeatureInfoWindow = useSelector((state: any) => state.selectFeature.featureInfoWindow);

    if (!selectedFeature || !selectedFeatureInfoWindow) return null;

  return (
    <>
    <RLayerVector>
      <RFeature geometry={selectedFeature.getGeometry()}>
        <ROverlay positioning='top-left'>
          <CustomInfoWindow 
            infowindow={selectedFeatureInfoWindow}
            properties={selectedFeature.getProperties()}/>
          </ROverlay>
        </RFeature>
      </RLayerVector>
    </>
  );
};