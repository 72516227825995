import { getLayerByName } from "./index";
import { Feature } from 'ol';
import { Layer } from "../config/interfaces";

export function getFeatureInfowindow(feature: Feature, layers: Layer[] | undefined) {
    const featureId = feature.getId();

    let featureInfowindow = null;

    if (featureId && typeof featureId === 'string') {
        const layerName = featureId.split('.')[0];

        if (layers && layers.length > 0) {
            const layer_config = getLayerByName(layers, layerName);
            if (JSON.stringify(layer_config?.infowindow) !== '{}') {
                featureInfowindow = layer_config?.infowindow;
            }
        }
    }
    return featureInfowindow;
}