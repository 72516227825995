import { useSelector } from "react-redux";
import styles from "./Navbar.module.scss";
import { Link } from "react-router-dom";
import { RootState } from "../../store";

export default function Navbar({ activePage }: { activePage: string }) {
  const userInfo = useSelector((store: RootState) => store.auth.userInfo);

  const links: { [key: string]: string } = {
    Путеводитель: "/map",
    Новости: "/news",
    // Документы: "/documents",
    "О нас": "/about",
    // Контакты: "/contacts",
    Авторизация: "/login",
  };

  if (userInfo && userInfo?.role !== "user") {
    links["Управление"] = "/management";
  }

  return (
    <nav className={styles.header}>
      <div className={styles.container}>
        <nav className={styles.navbar}>
          {Object.keys(links).map((link) => (
            <Link
              to={links[link]}
              className={
                link === activePage
                  ? styles.linkContainerActive
                  : styles.linkContainer
              }
              key={link}
            >
              <div className={styles.linkTitle}>{link}</div>
              <div className={styles.linkLineActive} />
            </Link>
          ))}
        </nav>
      </div>
    </nav>
  );
}
