import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getTokensFromStorage } from "./auth/tokenService";
import { useSelector } from "react-redux";
import { RootState } from "./store";

export const PrivateRoute = () => {
  const authData = useSelector((store: RootState) => store.auth);
  const { userInfo, isLoading } = authData;
  const tokens = getTokensFromStorage();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  return tokens ? (
    userInfo && userInfo?.role !== "user" ? (
      <Outlet />
    ) : (
      <Navigate to="/map" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
