import { useState } from "react";
import styles from "./LinkElement.module.scss";
import Button from "../Button/Button";
import classNames from "classnames";
import { sanitizeFileName } from "../../common/utils";

export interface LinkElementProps {
  postId: number;
  postTitle: string;
  accessedLink?: boolean;
  isDocsType?: boolean;
  fileLink?: string;
  goToPostById: (postId: number) => void;
  handleLoginNavigate: () => void;
}

export default function LinkElement({
  postId,
  postTitle,
  accessedLink,
  isDocsType,
  fileLink,
  goToPostById,
  handleLoginNavigate,
}: LinkElementProps) {
  const [tooltip, setTooltip] = useState<boolean>(false);

  const handleOpenFile = () => {
    if (isDocsType && fileLink) {
      window.open(fileLink, "_blank");
    }
  };

  const handleDownloadFile = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (fileLink) {
      try {
        const response = await fetch(fileLink);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = sanitizeFileName(postTitle);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Download failed:", error);
      }
    }
  };

  return (
    <div
      onMouseEnter={!accessedLink ? () => setTooltip(true) : undefined}
      onMouseLeave={!accessedLink ? () => setTooltip(false) : undefined}
    >
      <div
        className={
          accessedLink ? styles.mapLinkContainer : styles.lockMapLinkContainer
        }
        onClick={
          accessedLink
            ? isDocsType
              ? handleOpenFile
              : () => goToPostById(postId)
            : undefined
        }
      >
        <div
          className={classNames(styles.mapLinkIcon, {
            [styles.lockIcon]: !accessedLink,
            [styles.docsIcon]: isDocsType,
          })}
        />
        <div className={accessedLink ? styles.mapLink : styles.lockLink}>
          {postTitle}
        </div>
        {isDocsType && (
          <div
            className={classNames(styles.download)}
            onClick={handleDownloadFile}
          />
        )}
      </div>
      {!accessedLink && (
        <div
          className={
            tooltip ? styles.tooltipContainer : styles.tooltipContainerDisabled
          }
        >
          <div className={styles.title}>
            Для получения доступа к карте, пожалуйста, войдите в систему
          </div>
          <div className={styles.buttonContainer}>
            <Button title={"Войти"} onClick={handleLoginNavigate} />
          </div>
        </div>
      )}
    </div>
  );
}
