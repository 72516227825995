import { useState, useEffect } from 'react';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Feature } from 'ol';
import { FeatureCollection } from 'geojson';

interface UseWFSLayerProps {
  url: string;
  layer: any;
  sortBy?: string | null;
  sortOrder?: 'asc' | 'desc';
}

const useWFSLayer = ({ url, layer, sortBy, sortOrder = 'desc' }: UseWFSLayerProps) => {
  const [source] = useState(new VectorSource());
  const [geojson, setGeojson] = useState<FeatureCollection | null>(null);

  let layer_url = `${url}${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=10000&outputFormat=application%2Fjson`;
  
  if (sortBy) {
    layer_url += `&SORTBY=${sortBy}+${sortOrder}`;
  }

  useEffect(() => {
    const fetchWFSData = async () => {
      try {
        const response = await fetch(layer_url);
        const geojsonData = await response.json();
        setGeojson(geojsonData);

        const features = new GeoJSON().readFeatures(geojsonData, {
          featureProjection: 'EPSG:3857',
        });

        source.clear();
        source.addFeatures(features as Feature[]);
      } catch (error) {
        console.error('Error fetching WFS data:', error);
      }
    };

    fetchWFSData();
  }, [layer_url, source]);

  return {
    layer_url,
    geojson,
    source, // Возвращаем векторный источник
  };
};

export default useWFSLayer;
