import { Field, useFormikContext } from "formik";
import { ContentBlock, Post, MenuItem } from "../types/content-types";
import { ChangeEventHandler } from "react";
import styles from "./Components.module.scss";
import {
  barChartDefaultData,
  barChartDefaultRowData,
  contentBlockTypes,
  donutChartDefaultData,
  donutChartDefaultRowData,
  pieChartDefaultData,
  pieChartDefaultRowData,
} from "./constants";
import TextForm from "./SubForms/TextForm";
import TagsForm from "./SubForms/TagsForm";
import URLForm from "./SubForms/URLForm";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { checkForNestedField } from "../common/utils";

import React from "react";
import Button from "../components/Button/Button";
import TagsHorizontalForm from "./SubForms/TagsHorizontalForm";
import SolidScaleForm from "./SubForms/SolidScaleForm";
import GapScaleForm from "./SubForms/GapScaleForm";
import { useEditorField } from "./SubForms/useEditorField";
import { SubFormProps } from "./types";
import BarChartForm from "./SubForms/BarChartForm";
import DonutChartForm from "./SubForms/DonutChartForm";

type Props = {
  center?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children: JSX.Element | JSX.Element[] | any;
};

export const FlexContainer: React.FunctionComponent<Props> = ({
  children,
  style,
  className,
  center,
}) => (
  <div
    style={{
      display: "flex",
      ...(center && { justifyContent: "center" }),
      ...style,
    }}
    className={className}
  >
    <>{children}</>
  </div>
);

type InputBase = {
  isValid?: boolean;
  validationMessage?: string;
  name?: string;
  label?: string;
  value?: number | string;
  placeholder?: string;
  style?: React.CSSProperties;
};

type SelectProps = {
  onChange?: (
    e: React.ChangeEvent<HTMLSelectElement> & { target: { name: string } }
  ) => void;
  options: MenuItem[];
  showIds?: boolean;
  isNewOptionAvailable?: boolean;
  isChooseOptionFirst?: boolean;
  disabled?: boolean;
} & InputBase;

export const Select = ({
  onChange,
  options,
  isValid,
  validationMessage,
  name,
  label,
  value,
  showIds = false,
  isNewOptionAvailable = false,
  isChooseOptionFirst = true,
  disabled = false,
}: SelectProps) => {
  const optionValue = value ?? "";
  return (
    <div className={styles.inputBase}>
      <label htmlFor="select" className={styles.inputBaseLabel}>
        {label}
      </label>
      <div className={styles.inputBaseContainer}>
        <Field
          as="select"
          name={name}
          id="select"
          onChange={onChange}
          value={optionValue}
          className={styles.inputBaseInput}
          disabled={disabled}
        >
          {isChooseOptionFirst && <option value={""}>-- Не выбрано --</option>}

          {isNewOptionAvailable && (
            <option value={"new"}>-- СОЗДАТЬ НОВОЕ --</option>
          )}
          {options?.map((option) => (
            <option id={option.id.toString()} key={option.id} value={option.id}>
              {`${option.tag ?? ""} ${option.title}`}
              {showIds && ` - id: ${option.id}`}
            </option>
          ))}
        </Field>
        {!isValid && (
          <div className={styles.validationMessage}>{validationMessage}</div>
        )}
      </div>
    </div>
  );
};

type TextFieldProps = {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> & { target: { name: string } }
  ) => void;
} & InputBase;

export const TextField = ({
  onChange,
  value,
  name,
  label,
  isValid,
  validationMessage,
  style,
}: TextFieldProps) => {
  return (
    <div className={styles.inputBase}>
      <label htmlFor={name} className={styles.inputBaseLabel}>
        {label}
      </label>
      <div className={styles.inputBaseContainer}>
        <Field
          as="input"
          type="text"
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          className={styles.inputBaseInput}
          style={style}
        />
        {!isValid && (
          <div className={styles.validationMessage}>{validationMessage}</div>
        )}
      </div>
    </div>
  );
};

export const TextArea = ({
  name,
  label,
  isValid,
  validationMessage,
  ...textAreaProps
}: React.AllHTMLAttributes<HTMLTextAreaElement> & InputBase) => {
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <Field
        as="textarea"
        name={name}
        id={name}
        onChange={textAreaProps.onChange}
        value={textAreaProps.value}
        rows={textAreaProps.rows}
        style={{ padding: 10, resize: "vertical" }}
        placeholder={textAreaProps.placeholder}
      />
      {!isValid && (
        <div style={{ color: "red", fontSize: "0.7rem" }}>
          {validationMessage}
        </div>
      )}
    </>
  );
};

export const Checkbox = ({
  isChecked,
  handleCheckboxChange,
  title,
}: {
  isChecked: boolean;
  handleCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  title: string;
}) => {
  return (
    <label className={styles.checkboxLabel}>
      <span className={styles.checkboxTitle}>{title}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className={styles.checkboxInput}
      />
    </label>
  );
};

export const generateEmptyBlockValuesByType = (type: number): ContentBlock => {
  const genTextFields = () => ({
    header: "",
    sub_header: "",
    description: "",
    paragraph: "",
  });

  const genTextBlock = () => ({
    ...genTextFields(),
    type: 10,
    map_id: undefined,
  });

  const blocks: { [key: number]: ContentBlock } = {
    10: genTextBlock(),
    20: {
      ...genTextFields(),
      tags: [],
      type: 20,
      map_id: undefined,
    },
    30: {
      ...genTextFields(),
      tags: [],
      type: 30,
      map_id: undefined,
    },
    40: {
      ...genTextFields(),
      url: "",
      x_data: [],
      y_data: [],
      x_legend: [],
      y_legend: [],
      type: 40,
      map_id: undefined,
    },
    80: {
      ...genTextFields(),
      type: 80,
      scale: {
        data: [],
        legend: [],
        sub_type: "solid",
      },
      map_id: undefined,
    },
    81: {
      ...genTextFields(),
      type: 81,
      scale: {
        data: [],
        legend: [],
        sub_type: "gap",
      },
      map_id: undefined,
    },
    100: {
      ...genTextFields(),
      type: 100,
      bar_chart: barChartDefaultData,
      raw_data: barChartDefaultRowData,
    },
    101: {
      ...genTextFields(),
      type: 101,
      donut_chart: donutChartDefaultData,
      raw_data: donutChartDefaultRowData,
    },
    102: {
      ...genTextFields(),
      type: 102,
      donut_chart: pieChartDefaultData,
      raw_data: pieChartDefaultRowData,
    },
    1: {
      nested: [genTextBlock()],
      type: 1,
    },
  };

  return blocks[type];
};

export const EditorContentBlockBase = ({
  children,
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps & { children: React.ReactElement }) => {
  const { fieldName, blockValue, handleChange } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });
  return (
    <div>
      <Field
        as={TextField}
        label="Подзаголовок"
        name={fieldName("sub_header")}
        value={blockValue?.sub_header}
        onChange={handleChange}
      />
      <Field
        as={TextArea}
        label="Описание"
        name={fieldName("description")}
        value={blockValue?.description}
        onChange={handleChange}
      />
      <Field
        as={TextArea}
        label="Параграф"
        name={fieldName("paragraph")}
        value={blockValue?.paragraph}
        onChange={handleChange}
      />
      {children}
    </div>
  );
};

const ContentBlockByType = (
  index: number,
  type?: number,
  isNested?: boolean,
  nestedIndex?: number
) => {
  if (type) {
    const types: { [key: number]: JSX.Element } = {
      1: <NestedBlocks index={index} />,
      10: (
        <TextForm index={index} isNested={isNested} nestedIndex={nestedIndex} />
      ),
      20: (
        <TagsForm index={index} isNested={isNested} nestedIndex={nestedIndex} />
      ),
      30: (
        <TagsHorizontalForm
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
      40: (
        <URLForm index={index} isNested={isNested} nestedIndex={nestedIndex} />
      ),
      80: (
        <SolidScaleForm
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
      81: (
        <GapScaleForm
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
      100: (
        <BarChartForm
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
      101: (
        <DonutChartForm
          key={101}
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
      102: (
        <DonutChartForm
          key={102}
          index={index}
          isNested={isNested}
          nestedIndex={nestedIndex}
        />
      ),
    };
    if (type === 10 || type === 1) {
      return types[type];
    }

    return (
      <EditorContentBlockBase
        index={index}
        isNested={isNested}
        nestedIndex={nestedIndex}
      >
        {types[type]}
      </EditorContentBlockBase>
    );
  } else {
    return <></>;
  }
};

export function Blocks() {
  const { values, setFieldValue } = useFormikContext<Post>();
  const { mapsList } = useSelector((state: RootState) => state.map);
  const handleDeleteBlock = (index: number) => {
    const filteredBlocks = values.content_blocks.filter(
      (_, blockIndex) => blockIndex !== index
    );
    setFieldValue("content_blocks", filteredBlocks);
  };

  const hasNested = checkForNestedField(values.content_blocks);

  const addBlock = () => {
    const newBlock: ContentBlock = {
      ...generateEmptyBlockValuesByType(10),
    };
    setFieldValue("content_blocks", [...values.content_blocks, newBlock]);
  };

  const addNestedBlock = () => {
    const newBlock: ContentBlock = {
      ...generateEmptyBlockValuesByType(1),
    };
    setFieldValue("content_blocks", [...values.content_blocks, newBlock]);
  };

  return (
    <>
      {values.content_blocks.map((_, index) => (
        <div className={styles.block} key={index}>
          <div className={styles.blockTitle}>
            <h4>Блок №{index + 1}</h4>
            <Button
              onClick={() => handleDeleteBlock(index)}
              type="button"
              title="Удалить блок"
            />
          </div>

          <div className={styles.wrapper}></div>

          {!hasNested && (
            <Select
              onChange={(e) => {
                const value = e.target.value ? Number(e.target.value) : "";
                setFieldValue(
                  `content_blocks.[${index}]`,
                  generateEmptyBlockValuesByType(Number(value))
                );
              }}
              options={contentBlockTypes}
              name={`content_blocks.[${index}].type`}
              label="Тип контент блока"
              value={values.content_blocks[index].type}
            />
          )}

          <Select
            onChange={(e) => {
              const { value } = e.target;
              const properVal = Number(value) === 0 ? undefined : Number(value);
              setFieldValue(`content_blocks.[${index}].map_id`, properVal);
            }}
            options={mapsList}
            name={`content_blocks.[${index}].map_id`}
            label="Выбрать дополнительный слой для блока"
            value={values.content_blocks[index].map_id}
          />

          {ContentBlockByType(index, values.content_blocks[index].type)}
        </div>
      ))}
      {!hasNested && (
        <div className={styles.containerCenter}>
          <Button
            type="button"
            onClick={addBlock}
            title="  Добавить обычный блок"
          />
        </div>
      )}

      {(values.content_blocks.length === 0 || hasNested) && (
        <div className={styles.containerCenter}>
          <Button
            type="button"
            onClick={addNestedBlock}
            title="Добавить `вложенные блоки` блок"
          />
        </div>
      )}
    </>
  );
}

export function NestedBlocks({ index }: { index: number }) {
  const { values, setFieldValue } = useFormikContext<Post>();

  const handleDeleteBlock = (nestedIndex: number) => {
    const filteredBlocks = values.content_blocks[index].nested?.filter(
      (_, blockIndex) => blockIndex !== nestedIndex
    );
    setFieldValue(`content_blocks.[${index}].nested`, filteredBlocks);
  };

  const addBlock = () => {
    const newBlock: ContentBlock = {
      ...generateEmptyBlockValuesByType(10),
    };
    setFieldValue(`content_blocks.[${index}].nested`, [
      ...(values.content_blocks[index].nested ?? []),
      newBlock,
    ]);
  };

  return (
    <>
      <h5>Вложенные блоки для блока № {index + 1}</h5>
      {values.content_blocks[index].nested?.map((_, nestedIndex) => (
        <div className={styles.nestedBlock} key={nestedIndex}>
          <div className={styles.blockTitle}>
            <h4>Вложенный блок №{nestedIndex + 1}</h4>

            {nestedIndex > 0 && (
              <Button
                onClick={() => handleDeleteBlock(nestedIndex)}
                type="button"
                title="Удалить вложенный блок"
              />
            )}
          </div>

          <div className={styles.wrapper}>
            {nestedIndex > 0 && (
              <Select
                onChange={(e) => {
                  const value = e.target.value ? Number(e.target.value) : "";
                  setFieldValue(
                    `content_blocks.[${index}].nested.${nestedIndex}`,
                    generateEmptyBlockValuesByType(Number(value))
                  );
                }}
                options={contentBlockTypes}
                name={`content_blocks.[${index}].nested.${nestedIndex}.type`}
                label="Тип контент блока"
                value={values.content_blocks[index].nested![nestedIndex].type}
              />
            )}

            {values.content_blocks[index].nested![nestedIndex].type !== 1 &&
              ContentBlockByType(
                index,
                values.content_blocks[index].nested![nestedIndex].type,
                true,
                nestedIndex
              )}
          </div>
        </div>
      ))}
      <div className={styles.containerCenter}>
        <Button
          type="button"
          onClick={addBlock}
          title="Добавить вложенный блок"
        />
      </div>
    </>
  );
}
