import React, { useCallback } from 'react';
import { RLayerVector } from 'rlayers';
import { FeatureLike } from 'ol/Feature';
import createFeatureStyle from './maps/common/StyleFeaturePoint';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { Layer } from "./config/interfaces";
import { calculateSquareSide } from "./utils";

interface PointLayerProps {
  url: string;
  layer: Layer;
  order: number;
}

const PointLayer: React.FC<PointLayerProps> = ({ url, layer, order }) => {

  let sortByField = null;

  if (layer.geometry_type === "diagramm") {
    if (layer.marker?.size && typeof layer.marker?.size !== 'number') {
      sortByField = layer.marker?.size;
    }
  }

  const { layer_url } = useWFSLayer({ url, layer, sortBy: sortByField });

  const createFeatureStyleCallback = useCallback(
    (feature: FeatureLike) => {
      const properties = feature.getProperties();
      const fillColor = properties['color_hex'];
      let size = layer.marker?.size
        ? Number(properties[layer.marker.size]) * (Number(layer.marker?.size_multiplication) || 1)
        : 0;
      const valuesRange = layer.marker?.values_range;
      if (valuesRange && layer.marker?.size) {
        size = calculateSquareSide(Number(properties[layer.marker.size]), valuesRange);
      }
      
      const edges = layer.marker?.edges ? layer.marker?.edges : 4;
      const outlineColor = layer.marker?.outline_color ? layer.marker?.outline_color : '#ffffff';
      const outlineWidth = layer.marker?.outline_width ? layer.marker?.outline_width : 1;

      return createFeatureStyle({
        feature,
        isHovered: false,
        fillColor,
        outlineColor: outlineColor,
        outlineWidth: outlineWidth,
        size,
        edges,
      });
    },
    [layer.marker]
  );

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
      style={createFeatureStyleCallback}
    />
  );
};

export default PointLayer;