import styles from "./ModalWindow.module.scss";

import parse from "html-react-parser";
import { createPortal } from "react-dom";
import Button from "../Button/Button";
import { capitalizeFirstLetter } from "../../common/utils";

export function ModalElement({
  title,
  subtitle,
  content,
  onClose,
}: ModalWindowProps) {
  return (
    <div className={styles.modalWindow}>
      <div className={styles.scrollContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.titles}>
              {title && (
                <div className={styles.title}>
                  {capitalizeFirstLetter(title)}
                </div>
              )}
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
            <button className={styles.closeButton} onClick={onClose}></button>
          </div>
          {content && (
            <div className={styles.parseHTML}>
              {(() => {
                try {
                  return parse(content);
                } catch (error) {
                  console.error("Error parsing HTML:", error);
                  return <p>Ошибка при разборе данных</p>;
                }
              })()}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              title="Закрыть"
              style={{ marginTop: 8 }}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export interface ModalWindowProps {
  isVisible: boolean;
  title?: string;
  subtitle?: string;
  content?: string;
  onClose: () => void;
}

export default function ModalWindow({ ...props }: ModalWindowProps) {
  const element = props.isVisible ? (
    <div
      className={styles.backdrop}
      onClick={(event) => event.stopPropagation()}
    >
      <ModalElement {...props} />
    </div>
  ) : null;

  return createPortal(element, document.body);
}
