import { Feature } from 'ol';
import { Marker } from "../config/interfaces";
import { calculateSquareSide } from "../utils";

export function calculateInfowindowOffset(geometryType: string, featureMarker: Marker, feature: Feature) {

    let size = Number(feature.getProperties()[featureMarker.size]) * (Number(featureMarker.size_multiplication) || 1);

    if (featureMarker?.values_range) {
        size = calculateSquareSide(Number(feature.getProperties()[featureMarker.size]), featureMarker?.values_range);
    }

    if (geometryType == "diagramm"){
        return size * Math.sqrt(2) / 2 + 2;
    }
    if (geometryType == "pie"){
        return size + 2;
    }
    else {
        return null;
    }
}