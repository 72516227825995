import React, { useCallback } from 'react';
import { RLayerVector, RStyle } from 'rlayers';
import { Feature } from 'ol';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { hexToRgba } from "./utils";

interface PolygonLayerProps {
  url: string;
  layer: any;
  order: number;
}

const PolygonLayer: React.FC<PolygonLayerProps> = ({ url, layer, order }) => {
  const { layer_url } = useWFSLayer({ url, layer });

  const getPolygonColor = (f: Feature) => {
    const properties = f.getProperties();
    const color_fill_hex = f.getProperties().color_hex?.trim() || "#ffffff";

    let opacity_fill = layer.marker?.opacity;
    if (!opacity_fill) {
      opacity_fill = Number(f.getProperties().opacity) || 100;
    }

    const color_fill = hexToRgba(color_fill_hex, opacity_fill);

    let outlineWidth = layer.marker?.outline_width;

    if (!outlineWidth) {
      outlineWidth = Number(f.getProperties().width) || 1;
    }

    return (
      <>
        <RStyle.RFill color={color_fill} />
        <RStyle.RStroke width={outlineWidth} color="#ffffff" />
      </>
    );
  };

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
    >
      <RStyle.RStyle render={useCallback(getPolygonColor, [])} />
    </RLayerVector>
  );
};

export default PolygonLayer;
