export function createDiagonalStripePattern(
  color1: string,
  color2: string,
  stripeWidth: number
): CanvasPattern | null {
  const canvas = document.createElement("canvas");
  const size = stripeWidth * 10; // Размер паттерна
  canvas.width = size;
  canvas.height = size;

  const context = canvas.getContext("2d");

  if (!context) {
    throw new Error("Не удалось получить 2D-контекст для canvas.");
  }

  // Заливка первого цвета
  context.fillStyle = color1;
  context.fillRect(0, 0, size, size);

  // Рисуем диагональную полосу второго цвета
  context.strokeStyle = color2;
  context.lineWidth = stripeWidth;
  context.beginPath();
  context.moveTo(-stripeWidth, 0);
  context.lineTo(size, size + stripeWidth);
  context.stroke();

  return context.createPattern(canvas, "repeat");
}