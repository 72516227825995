import React from "react";
import styles from "./LinkItem.module.scss";
import classNames from "classnames";

export interface LinkItemProps {
  title: string;
  handleClick?: () => void;
  checked?: boolean;
  style?: React.CSSProperties;
}

export default function LinkItem({
  title,
  handleClick,
  checked,
  style,
}: LinkItemProps) {
  return (
    <div
      className={styles.mapLinkContainer}
      style={style}
      onClick={handleClick ? () => handleClick() : undefined}
    >
      <div
        className={classNames(styles.mapLinkIcon, {
          [styles.checked]: checked,
        })}
      />
      <div className={styles.mapLink}>{title}</div>
    </div>
  );
}
