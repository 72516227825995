import { useRef, useState } from "react";
import styles from "./ButtonThreeDot.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";
import classNames from "classnames";
import SelectModule from "../SelectModule/SelectModule";

export interface ButtonThreeDotProps {
  /** Список опций */
  options: { [key: string]: string };
  /** Функция для обновления выбранного значения */
  onSelect: (selectedOption: string) => void;
  /** Дополнительные стили для контейнера */
  style?: React.CSSProperties;
}

export default function ButtonThreeDot({
  options,
  onSelect,
  style,
}: ButtonThreeDotProps) {
  const [isOpen, setIsOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(mainRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div ref={mainRef} style={style}>
      <div
        className={classNames(styles.button, {
          [styles.buttonOpen]: isOpen,
        })}
        onClick={toggleDropdown}
      />
      <SelectModule
        options={options}
        onSelect={handleSelect}
        isOpen={isOpen}
        isQuickClosure
        style={{ transform: "translateY(8px) translateX(-180px)" }}
      />
    </div>
  );
}
