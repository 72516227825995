import React from "react";
import styles from "./StatusLabel.module.scss";
import classNames from "classnames";

export type StatusLabelProps = {
  color: "green" | "blue" | "red" | "gray";
  title: string;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ color = "gray", title }) => {
  return (
    <div className={classNames(styles.statusContainer, styles[color])}>
      {title}
    </div>
  );
};

export default StatusLabel;
