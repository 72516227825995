import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import styles from "./Map.module.css";
import React, { useRef, useEffect, useState, useCallback } from 'react';
import View from 'ol/View';
import * as olProj from 'ol/proj';

import { useDispatch, useSelector } from "react-redux";

import { selectMap } from "../../redux/map/selectors";
import { RControl, RMap, RLayerTileWebGL, RLayerWMS, RLayerTileWMS } from "rlayers";
import ButtonScreenshot from "../ButtonScreenshot/ButtonScreenshot";
import { useConstructorMode } from "../../redux/constructorMode";

import MapLoader from "../Loaders/MapLoader/MapLoader";
import { deselect } from "../../redux/map/featureReducer";

import { InfoWindowOnClick } from "./InfoWindowOnClick";
import { setSelectedFeature, 
  setFeatureInfoWindow, 
  setInfoWindowFeatureOffset, 
  setZoom } from '../../redux/map/featureReducer';
import { getFeatureInfowindow, getFeatureMarker, calculateSquareSide, calculateInfowindowOffset } from "./utils";

import WFSLayer from "./WFSLayer";
import { Layer } from "./config/interfaces";
import ZoomHomeButton from "../MapControls/ZoomHomeButton";

//import { easeIn, easeOut } from 'ol/easing';
//import { generateSLD } from "./utils";

export default function Map() {
  const dispatch = useDispatch();
  const map_config = useSelector(selectMap).map_config;

  const [layers, setLayers] = useState<Layer[]>([]);
  const [prevProjection, setPrevProjection] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const mapRef = useRef<RMap>(null);
  const mapRefContainer = useRef<HTMLDivElement>(null);

  const isConstructorMode = useSelector(useConstructorMode);
  const zoom = useSelector((state: any) => state.selectFeature.currentZoom);

  // Register custom projection
  useEffect(() => {
    proj4.defs(
      "EPSG:3576",
      "+proj=laea +lat_0=90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs"
    );
    register(proj4);
  }, []);

  const defaultProjection = 'EPSG:3857';
  const mapProjection = map_config?.projection || defaultProjection;

  const initialView = {
    center: fromLonLat(map_config?.center || [30, 40]),
    zoom: map_config?.zoom.start || 3,
  };

  // Handle map view updates when projection or zoom changes
  const updateMapView = useCallback(() => {
    if (!mapRef.current) return;

    const map = mapRef.current.ol;
    if (!map) return;

    const projection = olProj.get(mapProjection);
    const view = map.getView();

    if (prevProjection !== mapProjection && projection) {
      const newView = new View({
        projection,
        center: olProj.fromLonLat(map_config?.center || [30, 40], projection),
        zoom: map_config?.zoom.start || 3,
      });

      map.setView(newView);
      setPrevProjection(mapProjection);
    } else if (view) {
      view.setZoom(map_config?.zoom.start || 3);
      view.setCenter(fromLonLat(map_config?.center || [30, 40]));
    }
  }, [map_config, mapProjection, prevProjection]);

  useEffect(updateMapView, [map_config, mapProjection, updateMapView]);

  // Update cursor and handle map clicks
  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;

    const handleMapClick = (event: any) => {
      let feature = map.forEachFeatureAtPixel(event.pixel, (feat: any) => feat);
      if (feature) {
        const view = map.getView();

        if (!view) {
          console.error("View объекта карты не найден.");
          return;
        }

        if (feature.get('features')?.length > 1) {
          const clusterFeatures = feature.get('features');

          const avgCoordinate = clusterFeatures.reduce(
            (acc: [number, number], f: any) => {
              const geom = f.getGeometry();
              if (geom && geom.getCoordinates) {
                const coords = geom.getCoordinates();
                acc[0] += coords[0];
                acc[1] += coords[1];
              }
              return acc;
            },
            [0, 0]
          ).map((val: number) => val / clusterFeatures.length);

          if (!avgCoordinate || avgCoordinate.some(isNaN)) {
            console.error("Не удалось вычислить координаты для анимации.");
            return;
          }

          view.animate({
            center: avgCoordinate,
            zoom: (view.getZoom() || 0) + 1,
            duration: 500,
          });

          return;
        }

        if (feature.getProperties().features && feature.getProperties().features.length == 1) {
          feature = feature.getProperties().features[0];
        }
        const featureInfowindow = getFeatureInfowindow(feature, map_config?.layers);
        const { geometryType, featureMarker } = getFeatureMarker(feature, map_config?.layers);

        if ((geometryType === "diagramm" || geometryType === "pie") && featureMarker?.size) {
          const offset = calculateInfowindowOffset(geometryType, featureMarker, feature);
          dispatch(setInfoWindowFeatureOffset(offset));
        }

        dispatch(setSelectedFeature(feature));
        dispatch(setFeatureInfoWindow(featureInfowindow));
      } else {
        dispatch(deselect());
      }
    };

    const handlePointerMove = (event: any) => {
      const mapTarget = map.getTarget();
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat: any) => feat);
      if (mapTarget instanceof HTMLElement) {
        mapTarget.style.cursor = feature ? 'pointer' : '';
      }
    };

    map.on('click', handleMapClick);
    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('click', handleMapClick);
      map.un('pointermove', handlePointerMove);
    };
  }, [map_config, dispatch]);

  // Update zoom state
  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;

    const view = map.getView();
    const handleZoomChange = () => {
      const zoom = view.getZoom();
      if (zoom) dispatch(setZoom(zoom));
    };

    view.on('change:resolution', handleZoomChange);
    return () => view.un('change:resolution', handleZoomChange);
  }, [dispatch]);



  // create layer
  const createLayer = (layerConfig: Layer) => {
      if (layerConfig.geometry_type === 'wms') {

        //const sldGenerated = generateSLD(layerConfig);
        
        return (
          <RLayerTileWMS
            key={layerConfig.layer}  // Используем уникальный id слоёв
            url={"https://geo.touristatlas.ru/geoserver/wms"}
            params={{
              LAYERS: `${layerConfig.workspace}:${layerConfig.layer}`,
              FORMAT: 'image/png',
              STYLES: layerConfig.marker?.sld ? layerConfig.marker?.sld : null,
              TILED: true,
            }}
          />
        );
      }
        return null;
      };

      useEffect(() => {
        if (map_config?.layers) {
          setLayers(map_config.layers.filter((layerConfig: any) => layerConfig.geometry_type === 'wms'));
        }
      }, [map_config?.layers]);


  return (
    <>
    {isConstructorMode && <ButtonScreenshot elementRef={mapRefContainer.current} />}
    <div className={styles.mapWrap} ref={mapRefContainer}>
      {isLoading && <MapLoader />}
      <ZoomHomeButton onClick={() => updateMapView()} />
      <RMap 
        ref={mapRef}
        width="100%" 
        height="100%" 
        projection={mapProjection}
        //extent={mapProjection === 'EPSG:3857' ? mapExtent : undefined}
        enableRotation={false}
        initial={initialView}
        minZoom={map_config?.zoom.min ? map_config.zoom.min : 1}
        maxZoom={map_config?.zoom.max ? map_config.zoom.max : 24}
        noDefaultControls
        onRenderComplete={() => setIsLoading(false)}
      >
        <RControl.RZoom className={styles.zoom} />
        <InfoWindowOnClick />
        {mapProjection === 'EPSG:3857' ? (
          <>
          <RLayerTileWebGL
            properties={{ label: "Custom" }}
            url="https://tiles.touristatlas.ru/tile/{z}/{x}/{y}.png"
            attributions="Custom layers"
          />
          {layers.map((layerConfig: any) => createLayer(layerConfig))}

          {map_config?.layers.map((layerConfig: any, index: number) =>
            layerConfig.geometry_type !== 'wms' ? (
              <WFSLayer
                key={layerConfig.layer}
                url={'https://geo.touristatlas.ru/geoserver/'}
                layer={layerConfig}
                order={map_config?.layers.length - index}
              />
            ) : null
          )}
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_country_boundary_line',
                FORMAT: 'image/png',
                STYLES: 'country_boundary_line',
                TILED: true,
              }}
            />
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_subject_boundary_line_wo_coastline',
                FORMAT: 'image/png',
                STYLES: 'subject_boundary_line',
                TILED: true,
              }}
              visible={zoom > 5}
            />
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_rayons_boundary_line_wo_coastline',
                FORMAT: 'image/png',
                STYLES: 'subject_boundary_line',
                TILED: true,
              }}
              visible={zoom > 7}
            />
          </>
        ) : (
          <>
          <RLayerWMS
            url={map_config?.basemap ? map_config.basemap : ''}
            params={{
            bgcolor: "0xD3D5DA",
            TRANSPARENT: false,
            }}>
          </RLayerWMS>
          {layers.map((layerConfig: any) => createLayer(layerConfig))}

          {map_config?.layers.map((layerConfig: any, index: number) =>
            layerConfig.geometry_type !== 'wms' ? (
              <WFSLayer
                key={layerConfig.layer}
                url={'https://geo.touristatlas.ru/geoserver/'}
                layer={layerConfig}
                order={map_config?.layers.length - index}
              />
            ) : null
          )}
          </>
          )}
        </RMap>
    </div>
    </>
  );
  
}
