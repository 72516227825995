import { Style, Fill, Stroke, RegularShape, Text } from 'ol/style';
import { FeatureLike } from 'ol/Feature';

interface FeatureStyleProps {
  feature: FeatureLike;
  isHovered: boolean;
  fillColor: string;
  outlineColor: string;
  outlineWidth: number;
  size?: number;
  edges?: number;
  text?: string;
}

// Функция, возвращающая стиль
const createFeatureStyle = ({ feature, isHovered, fillColor, outlineColor, outlineWidth, size, edges, text }: FeatureStyleProps) => {
  let feature_size = 12;
  if (size) {
    feature_size = size;
  }
  if (text) {
  return new Style({
    image: new RegularShape({
      fill: new Fill({
        color: isHovered ? '#00aaff' : fillColor,
      }),
      stroke: new Stroke({
        color: outlineColor,
        width: isHovered ? 0 : outlineWidth,
      }),
      points: edges ? edges : 4,
      radius: feature_size,
      angle: (edges == 4) ? Math.PI / 4 : 0,
    }),
    text: new Text({
      text: text ? text : '',
      font: 'normal 700 14px "Nunito Sans", sans-serif',
      fill: new Fill({ color: '#ffffff' }),
      //stroke: new Stroke({ color: '#ffffff', width: 3 }),
      offsetX: 0,
      offsetY: 0,
      textAlign: 'center',
      textBaseline: 'middle',
    }),
  });
}
else {
  return new Style({
    image: new RegularShape({
      fill: new Fill({
        color: isHovered ? '#00aaff' : fillColor,
      }),
      stroke: new Stroke({
        color: outlineColor,
        width: isHovered ? 0 : outlineWidth, // Убираем обводку при наведении
      }),
      points: edges ? edges : 4,
      radius: feature_size,
      angle: (edges == 4) ? Math.PI / 4 : 0, // Поворот на 45 градусов
    }),
  })
}
};

export default createFeatureStyle;