import AddPostForm from "../edit/EditorForms/AddPostForm";
import Main from "../components/Map/MapOverLayer";
import AddMapForm from "../edit/EditorForms/AddMapForm";

import { useMenu } from "../components/SideBar/useMenu";
import AddFileForm from "../edit/EditorForms/AddFileForm";
import MenuButton from "../components/MenuButton/MenuButton";
import { useNavigate } from "react-router-dom";
import AddModalForm from "../edit/EditorForms/AddModalForm";

export default function EditorPage({
  type,
}: {
  type: "addMap" | "addPost" | "file" | "modal";
}) {
  const navigate = useNavigate();
  useMenu();
  const forms = {
    addMap: <AddMapForm />,
    addPost: <AddPostForm />,
    file: <AddFileForm />,
    modal: <AddModalForm />,
  };

  const menuActions: { [key: string]: () => void } = {
    map: () => {
      navigate(`/map`);
    },
    addMap: () => {
      navigate(`/edit/layer`);
    },
    addPost: () => {
      navigate(`/edit/map`);
    },
    file: () => {
      navigate("/edit/file");
    },
    modal: () => {
      navigate("/edit/modal");
    },
  };

  const menuOptions: { [key: string]: string } = {
    map: "Путеводитель",
    addMap: "Редактор слоя",
    addPost: "Редактор карты",
    file: "Редактор файлов",
    modal: "Модальное окно",
  };

  const handleMenuSelect = (option: string) => {
    const action = menuActions[option];
    if (action) action();
  };

  return (
    <>
      <MenuButton
        options={menuOptions}
        onSelect={handleMenuSelect}
        selectedOption={type}
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
      />
      <Main>
        <div
          style={{
            pointerEvents: "auto",
            margin: "20px auto",
            borderRadius: "10px",
          }}
        >
          {forms[type]}
        </div>
      </Main>
    </>
  );
}
