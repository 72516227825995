import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { close as modalClose } from "../../redux/modal";
import ModalWindow from "./ModalWindow";

export default function ModalWindowContainer() {
  const dispatch = useDispatch<AppDispatch>();

  const { isVisible, title, subtitle, content } = useSelector(
    (state: RootState) => state.modalWindow
  );
  const handleCloseModal = () => {
    dispatch(modalClose());
  };

  return (
    <ModalWindow
      isVisible={isVisible}
      title={title}
      subtitle={subtitle}
      content={content}
      onClose={handleCloseModal}
    />
  );
}
