import React, { useCallback } from 'react';
import { RLayerVector, RStyle } from 'rlayers';
import { Feature } from 'ol';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { hexToRgba, getColorByScale } from "./utils";

interface PolygonLayerProps {
  url: string;
  layer: any;
  order: number;
}

const CartogramLayer: React.FC<PolygonLayerProps> = ({ url, layer, order }) => {
  const { layer_url } = useWFSLayer({ url, layer });

  let classified_prop = 'value';
  if (layer.classified_prop) {
    classified_prop = layer.classified_prop;
  };

  // a6caff 6ca2ff 2861f7  0039ce 0028ac
  const renderStyle = useCallback((f: any) => {
    let color_fill_hex = "#ffffff";
    if (layer.scale) {
      color_fill_hex = getColorByScale(f, classified_prop, layer.scale, "#ffffff");
    }
    const opacity_fill = Number(f.getProperties().opacity) || 100;
    const color_fill = hexToRgba(color_fill_hex, opacity_fill);
    return (
      <>
      <RStyle.RFill color={color_fill}/>
      <RStyle.RStroke width={0.5} color={'#f5f5f5'}/>
      </>
      ); 
  }, [classified_prop])

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
      //onClick={handleFeatureClick}
    >
      <RStyle.RStyle render={renderStyle}/>
    </RLayerVector>
  );
};

export default CartogramLayer;
