import { getUserInfo } from "../../auth/AuthApiService";
import { IUser } from "./../../types/user";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

type InitialState = {
  isLoading: boolean;
  userInfo: IUser | null;
};

const initialState: InitialState = {
  isLoading: true,
  userInfo: null,
};

export const getUserInfoThunk = createAsyncThunk(
  "auth/getUserInfo",
  getUserInfo
);

export const logout = createAction("LOGOUT");

const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(getUserInfoThunk.fulfilled, (state, action) => {
    state.userInfo = action.payload;
    state.isLoading = false;
  });
  builder.addCase(getUserInfoThunk.pending, (state, action) => {
    state.isLoading = true;
  });
  builder.addCase(logout, (state) => {
    state.userInfo = null;
  });
});

export default authReducer;
