import React from "react";
import styles from "./PageContainer.module.scss";

export type PageContainerProps = { children: any };

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    <div className={styles.main}>
      <div className={styles.pageContainer}>{children}</div>
    </div>
  );
};

export default PageContainer;
